import { GetStaticProps } from "next"
import Home, { query } from "@views/Home"
import { getClient } from "@utils/sanity"

export default Home

export const getStaticProps: GetStaticProps = async ({
  params = {},
  preview = false,
}) => {
  const pageData = await getClient(preview).fetch(query)

  return {
    props: { preview, pageData },
  }
}
