import React from "react"
import { groq } from "next-sanity"
import Layout from "@components/Layout/hoc/withCmsData"
import Helmet from "@components/Helmet"
import CmsContent from "@cms/CmsContent"
import { usePreviewSubscription } from "@utils/sanity"

export const query = groq`*[_type == "siteConfig"][0] {
  frontpage-> {
    title,
    seoTitle,
    seoDescription,
    "openGraphImage": openGraphImage.asset->url,
    content[] {
      _type == "anchorNav" => {...},
      _type == "banner" => {...},
      _type == "bannerCarousel" => {...},
      _type == "cardSlider" => {...},
      _type == "contentBlock" => {...},
      _type == "ctaCards" => {...},
      _type == "downloads" => {
        ...,
        downloads[] {
          ...,
          "image": image.asset->url,
          "mobileImage": mobileImage.asset->url,
          "download": download.asset->url,
        },
      },
      _type == "faq" => @->,
      _type == "featureBlock" => {
        ...,
        featureItems[] {
          ...,
          "image": image.asset->url,
        }
      },
      _type == "hero" => {...},
      _type == "heroCarousel" => {...},
      _type == "imageGrid" => {...},
      _type == "legalContent" => {...},
      _type == "logoGrid" => {
        ...,
        logos[] {
          ...,
          "image": image.asset->url,
        }
      },
      _type == "newsletterForm" => @->,
      _type == "productSlider" => {
        _key,
        _type,
        header,
        products[]-> {
          productName,
          releaseDate,
          "slug": slug.current,
          sku,
          tags,
          price,
          "category": categories[]->name,
          "image": mainImage.asset->url,
          variants[]-> {
            ...
          },
        },
        cta {
          label,
          displayButton,
          internalLink,
          externalLink
        }
      },
      _type == "reference" => @->,
      _type == "spacer" => {...},
      _type == "textBlock" => {...},
      _type == "verticalDotSpacer" => {...},
      _type == "verticalSpace" => {...},
      _type == "youtubeVideo" => {...},
      "placeholderImage": *[_type == "siteConfig"].placeholderImage {
  	    "url": asset->url
	    },
    }
  }
}`

const Home: any = ({ preview, pageData: staticData }) => {
  const { data: liveData } = usePreviewSubscription(query, {
    params: {},
    initialData: staticData,
    enabled: true,
  })

  const pageData = liveData || staticData
  return (
    <Layout>
      <Helmet
        title={
          pageData?.frontpage?.seoTitle ||
          pageData?.frontpage?.title ||
          "SKB Cases"
        }
        description={pageData?.frontpage?.seoDescription}
        image={pageData?.frontpage?.openGraphImage}
      />
      {pageData?.frontpage?.content && (
        <CmsContent content={pageData.frontpage.content} />
      )}
    </Layout>
  )
}

export default Home
